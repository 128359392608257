import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Participation`}</h1>
    <p>{``}<a parentName="p" {...{
        "href": "/voting",
        "title": "voting"
      }}>{`voting`}</a>{` and `}<a parentName="p" {...{
        "href": "/commenting",
        "title": "commenting"
      }}>{`commenting`}</a>{` by `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{``}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/polis-opinion-matrix",
        "title": "polis opinion matrix"
      }}>{`polis opinion matrix`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      